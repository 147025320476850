import styled from 'styled-components'
import NextLink from 'next/link'

export const Link = NextLink

export const UnstyledLink = styled(Link)`
  &,
  &:focus,
  &:active,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
  &[disabled] {
    pointer-events: none;
  }
`
