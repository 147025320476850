import styled from 'styled-components'
import PropTypes from 'prop-types'

import { mediaBreakpointUp } from '../../styles/media'

const Cell = styled.div`
  min-width: 0;
  grid-column-end: ${({ width = 1 }) => `span ${width}`};
  grid-row-end: ${({ height = 1 }) => `span ${height}`};

  ${({ left }) => left && `grid-column-start: ${left}`};
  ${({ top }) => top && `grid-row-start: ${top}`};

  ${({ center }) => center && 'text-align: center'};

  ${({ area }) => area && `grid-area: ${area}`};

  ${({ middle }) =>
    middle &&
    `
    display: inline-flex;
    flex-flow: column wrap;
    justify-content: center;
    justify-self: stretch;
  `};

  ${({ flex }) => flex && 'display: flex'};

  ${mediaBreakpointUp('sm')`
    ${({ widthSm }) => widthSm && `grid-column-end: span ${widthSm}`};
    ${({ heightSm }) => heightSm && `grid-row-end: span ${heightSm}`};
    ${({ leftSm }) => leftSm && `grid-column-start: ${leftSm}`};
  `};

  ${mediaBreakpointUp('md')`
    ${({ widthMd }) => widthMd && `grid-column-end: span ${widthMd}`};
    ${({ heightMd }) => heightMd && `grid-row-end: span ${heightMd}`};
    ${({ leftMd }) => leftMd && `grid-column-start: ${leftMd}`};
  `};

  ${mediaBreakpointUp('lg')`
    ${({ widthLg }) => widthLg && `grid-column-end: span ${widthLg}`};
    ${({ heightLg }) => heightLg && `grid-row-end: span ${heightLg}`};
    ${({ leftLg }) => leftLg && `grid-column-start: ${leftLg}`};
  `};

  ${mediaBreakpointUp('xl')`
    ${({ widthXl }) => widthXl && `grid-column-end: span ${widthXl}`};
    ${({ heightXl }) => heightXl && `grid-row-end: span ${heightXl}`};
    ${({ leftXl }) => leftXl && `grid-column-start: ${leftXl}`};
  `};
`

Cell.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  widthSm: PropTypes.number,
  widthMd: PropTypes.number,
  widthLg: PropTypes.number,
  widthXl: PropTypes.number,
  height: PropTypes.number,
  heightSm: PropTypes.number,
  heightMd: PropTypes.number,
  heightLg: PropTypes.number,
  heightXl: PropTypes.number,
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  leftSm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  leftMd: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  leftLg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  leftXl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  middle: PropTypes.bool,
  center: PropTypes.bool,
  area: PropTypes.string,
}

export default Cell
