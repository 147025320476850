// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

var onUnhandledException = {
  contents: (function (exn) {
      console.error("Unhandled exception in promise callback:");
      console.error(exn);
    })
};

function PromiseBox(p) {
    this.nested = p;
};

function unbox(value) {
    if (value instanceof PromiseBox)
        return value.nested;
    else
        return value;
}

function box(value) {
    if (value != null && typeof value.then === 'function')
        return new PromiseBox(value);
    else
        return value;
}

function make(executor) {
    return new Promise(function (resolve, reject) {
        var boxingResolve = function(value) {
            resolve(box(value));
        };
        executor(boxingResolve, reject);
    });
};

function resolved(value) {
    return Promise.resolve(box(value));
};

function then(promise, callback) {
    return promise.then(function (value) {
        try {
            return callback(unbox(value));
        }
        catch (exception) {
            onUnhandledException.contents(exception);
            return new Promise(function() {});
        }
    });
};

function catch_(promise, callback) {
    var safeCallback = function (error) {
        try {
            return callback(error);
        }
        catch (exception) {
            onUnhandledException.contents(exception);
            return new Promise(function() {});
        }
    };

    return promise.catch(safeCallback);
};
;

function pending(param) {
  var resolve = {
    contents: (function (prim) {
        
      })
  };
  var reject = {
    contents: (function (prim) {
        
      })
  };
  var p = make(function (resolve$p, reject$p) {
        resolve.contents = resolve$p;
        reject.contents = reject$p;
      });
  return [
          p,
          resolve.contents,
          reject.contents
        ];
}

function map(promise, callback) {
  return then(promise, (function (v) {
                return resolved(Curry._1(callback, v));
              }));
}

function get(promise, callback) {
  map(promise, callback);
}

function tap(promise, callback) {
  return map(promise, (function (v) {
                Curry._1(callback, v);
                return v;
              }));
}

function allArray(promises) {
  return map(Promise.all(promises), (function (promises) {
                return Belt_Array.map(promises, (function (prim) {
                              return unbox(prim);
                            }));
              }));
}

function all(promises) {
  return map(allArray(Belt_List.toArray(promises)), Belt_List.fromArray);
}

function all2(p1, p2) {
  return Promise.all([
              p1,
              p2
            ]);
}

function all3(p1, p2, p3) {
  return Promise.all([
              p1,
              p2,
              p3
            ]);
}

function all4(p1, p2, p3, p4) {
  return Promise.all([
              p1,
              p2,
              p3,
              p4
            ]);
}

function all5(p1, p2, p3, p4, p5) {
  return Promise.all([
              p1,
              p2,
              p3,
              p4,
              p5
            ]);
}

function all6(p1, p2, p3, p4, p5, p6) {
  return Promise.all([
              p1,
              p2,
              p3,
              p4,
              p5,
              p6
            ]);
}

function race(promises) {
  if (promises === /* [] */0) {
    throw {
          RE_EXN_ID: "Invalid_argument",
          _1: "Promise.race([]) would be pending forever",
          Error: new Error()
        };
  }
  return Promise.race(Belt_List.toArray(promises));
}

function toResult(promise) {
  return catch_(map(promise, (function (v) {
                    return {
                            TAG: "Ok",
                            _0: v
                          };
                  })), (function (e) {
                return resolved({
                            TAG: "Error",
                            _0: e
                          });
              }));
}

function fromResult(promise) {
  return then(promise, (function (x) {
                if (x.TAG === "Ok") {
                  return resolved(x._0);
                } else {
                  return Promise.reject(x._0);
                }
              }));
}

function pending$1(param) {
  var match = pending();
  return [
          match[0],
          match[1]
        ];
}

function exec(executor) {
  var match = pending$1();
  Curry._1(executor, match[1]);
  return match[0];
}

function resolved$1(prim) {
  return resolved(prim);
}

function flatMap(prim0, prim1) {
  return then(prim0, prim1);
}

function flatMapOk(promise, callback) {
  return then(promise, (function (result) {
                if (result.TAG === "Ok") {
                  return Curry._1(callback, result._0);
                } else {
                  return resolved(result);
                }
              }));
}

function flatMapError(promise, callback) {
  return then(promise, (function (result) {
                if (result.TAG === "Ok") {
                  return resolved(result);
                } else {
                  return Curry._1(callback, result._0);
                }
              }));
}

function mapOk(promise, callback) {
  return map(promise, (function (result) {
                if (result.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: Curry._1(callback, result._0)
                        };
                } else {
                  return result;
                }
              }));
}

function mapError(promise, callback) {
  return map(promise, (function (result) {
                if (result.TAG === "Ok") {
                  return result;
                } else {
                  return {
                          TAG: "Error",
                          _0: Curry._1(callback, result._0)
                        };
                }
              }));
}

function getOk(promise, callback) {
  get(promise, (function (result) {
          if (result.TAG === "Ok") {
            return Curry._1(callback, result._0);
          }
          
        }));
}

function getError(promise, callback) {
  get(promise, (function (result) {
          if (result.TAG === "Ok") {
            return ;
          } else {
            return Curry._1(callback, result._0);
          }
        }));
}

function tapOk(promise, callback) {
  getOk(promise, callback);
  return promise;
}

function tapError(promise, callback) {
  getError(promise, callback);
  return promise;
}

function allOkArray(promises) {
  var promiseCount = promises.length;
  if (promiseCount === 0) {
    return resolved({
                TAG: "Ok",
                _0: []
              });
  }
  var resultValues = Belt_Array.make(promiseCount, undefined);
  var resultCount = {
    contents: 0
  };
  var match = pending$1();
  var resolve = match[1];
  var match$1 = pending$1();
  var removeCallbacks = match$1[1];
  var callbackRemover = match$1[0];
  Belt_Array.forEachWithIndex(promises, (function (index, promise) {
          var wrapped = race({
                hd: promise,
                tl: {
                  hd: callbackRemover,
                  tl: /* [] */0
                }
              });
          get(wrapped, (function (result) {
                  if (result.TAG === "Ok") {
                    Belt_Array.setExn(resultValues, index, Caml_option.some(result._0));
                    resultCount.contents = resultCount.contents + 1 | 0;
                    if (resultCount.contents < promiseCount) {
                      return ;
                    }
                    var values = Belt_Array.map(resultValues, (function (v) {
                            if (v !== undefined) {
                              return Caml_option.valFromOption(v);
                            }
                            throw {
                                  RE_EXN_ID: "Assert_failure",
                                  _1: [
                                    "ReasonPromise.res",
                                    278,
                                    30
                                  ],
                                  Error: new Error()
                                };
                          }));
                    return Curry._1(resolve, {
                                TAG: "Ok",
                                _0: values
                              });
                  }
                  var e = result._0;
                  Curry._1(resolve, {
                        TAG: "Error",
                        _0: e
                      });
                  Curry._1(removeCallbacks, {
                        TAG: "Error",
                        _0: e
                      });
                }));
        }));
  return match[0];
}

function allOk(promises) {
  return mapOk(allOkArray(Belt_List.toArray(promises)), Belt_List.fromArray);
}

function allOk2(p1, p2) {
  return allOkArray([
              p1,
              p2
            ]);
}

function allOk3(p1, p2, p3) {
  return allOkArray([
              p1,
              p2,
              p3
            ]);
}

function allOk4(p1, p2, p3, p4) {
  return allOkArray([
              p1,
              p2,
              p3,
              p4
            ]);
}

function allOk5(p1, p2, p3, p4, p5) {
  return allOkArray([
              p1,
              p2,
              p3,
              p4,
              p5
            ]);
}

function allOk6(p1, p2, p3, p4, p5, p6) {
  return allOkArray([
              p1,
              p2,
              p3,
              p4,
              p5,
              p6
            ]);
}

var Operators = {
  $great$pipe$eq: mapOk,
  $great$great$eq: flatMapOk
};

function flatMapSome(promise, callback) {
  return then(promise, (function (option) {
                if (option !== undefined) {
                  return Curry._1(callback, Caml_option.valFromOption(option));
                } else {
                  return resolved(undefined);
                }
              }));
}

function mapSome(promise, callback) {
  return map(promise, (function (option) {
                if (option !== undefined) {
                  return Caml_option.some(Curry._1(callback, Caml_option.valFromOption(option)));
                }
                
              }));
}

function getSome(promise, callback) {
  get(promise, (function (option) {
          if (option !== undefined) {
            return Curry._1(callback, Caml_option.valFromOption(option));
          }
          
        }));
}

function tapSome(promise, callback) {
  getSome(promise, callback);
  return promise;
}

var PipeFirst = {};

function Js_resolved(prim) {
  return resolved(prim);
}

function Js_rejected(prim) {
  return Promise.reject(prim);
}

function Js_flatMap(prim0, prim1) {
  return then(prim0, prim1);
}

function Js_catch(prim0, prim1) {
  return catch_(prim0, prim1);
}

function Js_relax(prim) {
  return prim;
}

function Js_fromBsPromise(prim) {
  return prim;
}

function Js_toBsPromise(prim) {
  return prim;
}

var Js = {
  pending: pending,
  resolved: Js_resolved,
  rejected: Js_rejected,
  get: get,
  tap: tap,
  map: map,
  flatMap: Js_flatMap,
  $$catch: Js_catch,
  all: all,
  race: race,
  relax: Js_relax,
  toResult: toResult,
  fromResult: fromResult,
  fromBsPromise: Js_fromBsPromise,
  toBsPromise: Js_toBsPromise
};

exports.pending = pending$1;
exports.resolved = resolved$1;
exports.exec = exec;
exports.get = get;
exports.tap = tap;
exports.map = map;
exports.flatMap = flatMap;
exports.getOk = getOk;
exports.tapOk = tapOk;
exports.mapOk = mapOk;
exports.flatMapOk = flatMapOk;
exports.getError = getError;
exports.tapError = tapError;
exports.mapError = mapError;
exports.flatMapError = flatMapError;
exports.Operators = Operators;
exports.getSome = getSome;
exports.tapSome = tapSome;
exports.mapSome = mapSome;
exports.flatMapSome = flatMapSome;
exports.race = race;
exports.all = all;
exports.allArray = allArray;
exports.all2 = all2;
exports.all3 = all3;
exports.all4 = all4;
exports.all5 = all5;
exports.all6 = all6;
exports.allOk = allOk;
exports.allOkArray = allOkArray;
exports.allOk2 = allOk2;
exports.allOk3 = allOk3;
exports.allOk4 = allOk4;
exports.allOk5 = allOk5;
exports.allOk6 = allOk6;
exports.Js = Js;
exports.PipeFirst = PipeFirst;
exports.onUnhandledException = onUnhandledException;
/*  Not a pure module */
