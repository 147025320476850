// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css_Color = require("../common/Theme/Css_Color.bs.js");
var LinkJsx = require("./Link.jsx");
var Icon__Jsx3 = require("../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var ButtonLinkJsx = require("./ButtonLink.jsx");
var JsxRuntime = require("react/jsx-runtime");

var make = LinkJsx.UnstyledLink;

var UnstyledLink = {
  make: make
};

var make$1 = LinkJsx.Link;

var Link = {
  make: make$1
};

function toJsProps(jsProps) {
  return {
          href: jsProps.href,
          onClick: jsProps.onClick,
          disabled: jsProps.disabled,
          size: jsProps.size,
          icon: Core__Option.map(jsProps.icon, Icon__Jsx3.iconToString),
          className: jsProps.className,
          overlayText: jsProps.overlayText,
          tooltipText: jsProps.tooltipText,
          color: Css_Color.optToJs(jsProps.color),
          hoverColor: Css_Color.optToJs(jsProps.hoverColor),
          children: jsProps.children
        };
}

var make$2 = ButtonLinkJsx.PrimaryButton;

function make$3(props) {
  return JsxRuntime.jsx(make$2, toJsProps(props));
}

var PrimaryButton = {
  make: make$3
};

var make$4 = ButtonLinkJsx.SecondaryButton;

function make$5(props) {
  return JsxRuntime.jsx(make$4, toJsProps(props));
}

var SecondaryButton = {
  make: make$5
};

var make$6 = ButtonLinkJsx.DarkSecondaryButton;

function make$7(props) {
  return JsxRuntime.jsx(make$6, toJsProps(props));
}

var DarkSecondaryButton = {
  make: make$7
};

var make$8 = ButtonLinkJsx.CtaButton;

function make$9(props) {
  return JsxRuntime.jsx(make$8, toJsProps(props));
}

var CtaButton = {
  make: make$9
};

var make$10 = ButtonLinkJsx.DangerButton;

function make$11(props) {
  return JsxRuntime.jsx(make$10, toJsProps(props));
}

var DangerButton = {
  make: make$11
};

var make$12 = ButtonLinkJsx.OutlineButton;

function make$13(props) {
  return JsxRuntime.jsx(make$12, toJsProps(props));
}

var OutlineButton = {
  make: make$13
};

var ButtonProps = {};

exports.UnstyledLink = UnstyledLink;
exports.Link = Link;
exports.ButtonProps = ButtonProps;
exports.PrimaryButton = PrimaryButton;
exports.SecondaryButton = SecondaryButton;
exports.DarkSecondaryButton = DarkSecondaryButton;
exports.CtaButton = CtaButton;
exports.DangerButton = DangerButton;
exports.OutlineButton = OutlineButton;
/* make Not a pure module */
