// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var ReasonPromise = require("../reason/ReasonPromise.bs.js");

function fromJsPromise(v) {
  return ReasonPromise.Js.toResult(ReasonPromise.Js.fromBsPromise(v));
}

function toJsPromise(v) {
  return ReasonPromise.Js.toBsPromise(ReasonPromise.Js.fromResult(v));
}

function fromPromiseRejection(v) {
  return {
          name: Caml_option.nullable_to_opt(v.name),
          message: Caml_option.nullable_to_opt(v.message)
        };
}

var JsPromiseRejection = {
  fromPromiseRejection: fromPromiseRejection
};

exports.fromJsPromise = fromJsPromise;
exports.toJsPromise = toJsPromise;
exports.JsPromiseRejection = JsPromiseRejection;
/* ReasonPromise Not a pure module */
