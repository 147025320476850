import styled from 'styled-components'
import PropTypes from 'prop-types'
import { space } from 'styled-system'

import { mediaBreakpointUp } from '../../styles/media'

const autoRows = ({ minRowHeight = '20px' }) => `minmax(${minRowHeight}, auto)`

// eslint-disable-next-line no-shadow
const gap = ({ gap = '1rem' }) => gap

// eslint-disable-next-line no-shadow
const flow = ({ flow = 'row' }) => flow

const formatAreas = areas => areas.map(area => `'${area}'`).join(' ')

const Grid = styled.div`
  display: grid;
  grid-auto-flow: ${flow};
  grid-auto-rows: ${autoRows};
  ${({ rows }) => rows && `grid-template-rows: ${rows}`};
  ${({ columns = 12, columnsWidth = '1fr' }) =>
    columns && `grid-template-columns: repeat(${columns}, ${columnsWidth})`};
  grid-gap: ${gap};
  ${({ rowGap }) => rowGap && `grid-row-gap: ${rowGap}`};
  ${({ areas }) => areas && `grid-template-areas: ${formatAreas(areas)}`};
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
  ${({ alignContent }) => alignContent && `align-content: ${alignContent}`};

  ${mediaBreakpointUp('sm')`
    ${({ gapSm }) => gapSm && `grid-gap: ${gapSm}`};
    ${({ rowGapSm }) => rowGapSm && `grid-row-gap: ${rowGapSm}`};
    ${({ columnsSm, columnsWidthSm = '1fr' }) =>
      columnsSm && `grid-template-columns: repeat(${columnsSm}, ${columnsWidthSm})`};
    ${({ rowsSm }) => rowsSm && `grid-template-rows: ${rowsSm}`};
    ${({ justifyContentSm }) => justifyContentSm && `justify-content: ${justifyContentSm}`};
  `};
  ${mediaBreakpointUp('md')`
    ${({ gapMd }) => gapMd && `grid-gap: ${gapMd}`};
    ${({ rowGapMd }) => rowGapMd && `grid-row-gap: ${rowGapMd}`};
    ${({ columnsMd, columnsWidthMd = '1fr' }) =>
      columnsMd && `grid-template-columns: repeat(${columnsMd}, ${columnsWidthMd})`};
    ${({ rowsMd }) => rowsMd && `grid-template-rows: ${rowsMd}`};
    ${({ justifyContentMd }) => justifyContentMd && `justify-content: ${justifyContentMd}`};
  `};
  ${mediaBreakpointUp('lg')`
    ${({ gapLg }) => gapLg && `grid-gap: ${gapLg}`};
    ${({ rowGapLg }) => rowGapLg && `grid-row-gap: ${rowGapLg}`};
    ${({ columnsLg, columnsWidthLg = '1fr' }) =>
      columnsLg && `grid-template-columns: repeat(${columnsLg}, ${columnsWidthLg})`};
    ${({ rowsLg }) => rowsLg && `grid-template-rows: ${rowsLg}`};
    ${({ justifyContentLg }) => justifyContentLg && `justify-content: ${justifyContentLg}`};
  `};
  ${mediaBreakpointUp('xl')`
    ${({ gapXl }) => gapXl && `grid-gap: ${gapXl}`};
    ${({ rowGapXl }) => rowGapXl && `grid-row-gap: ${rowGapXl}`};
    ${({ columnsXl, columnsWidthXl = '1fr' }) =>
      columnsXl && `grid-template-columns: repeat(${columnsXl}, ${columnsWidthXl})`};
    ${({ rowsXl }) => rowsXl && `grid-template-rows: ${rowsXl}`};
    ${({ justifyContentXl }) => justifyContentXl && `justify-content: ${justifyContentXl}`};
  `};
  ${space};
`

Grid.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.number,
  columnsSm: PropTypes.number,
  columnsMd: PropTypes.number,
  columnsLg: PropTypes.number,
  columnsXl: PropTypes.number,
  rows: PropTypes.number,
  rowsSm: PropTypes.number,
  rowsMd: PropTypes.number,
  rowsLg: PropTypes.number,
  rowsXl: PropTypes.number,
  gap: PropTypes.string,
  gapSm: PropTypes.string,
  gapMd: PropTypes.string,
  gapLg: PropTypes.string,
  gapXl: PropTypes.string,
  minRowHeight: PropTypes.string,
  flow: PropTypes.string,
  areas: PropTypes.arrayOf(PropTypes.string),
  justifyContent: PropTypes.string,
  justifyContentSm: PropTypes.string,
  justifyContentMd: PropTypes.string,
  justifyContentLg: PropTypes.string,
  justifyContentXl: PropTypes.string,
  alignContent: PropTypes.string,
}

export default Grid
