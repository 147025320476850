import styled from 'styled-components'

import { makePrimaryButton } from 'uikit/atoms/Buttons/PrimaryButton'
import { makeCtaButton } from 'uikit/atoms/Buttons/CtaButton'
import { makeSecondaryButton } from 'uikit/atoms/Buttons/SecondaryButton'
import { makeDarkSecondaryButton } from 'uikit/atoms/Buttons/DarkSecondaryButton'
import { makeDangerButton } from 'uikit/atoms/Buttons/DangerButton'
import { makeOutlineButton } from 'uikit/atoms/Buttons/OutlineButton'
import { makeButton } from 'uikit/atoms/Buttons/Button'

import { UnstyledLink } from './Link'

const Button = makeButton(UnstyledLink)

// it's a hack to add styles here, but necessary since we have global styles on `a:hover`
const DarkBGButton = styled(Button)`
  color: ${props => props.theme.colors.white} !important;
  &:hover {
    color: ${props => props.theme.colors.white} !important;
  }
`

export const PrimaryButton = makePrimaryButton(DarkBGButton)
export const CtaButton = makeCtaButton(Button)
export const DangerButton = makeDangerButton(DarkBGButton)
export const SecondaryButton = makeSecondaryButton(Button)
export const DarkSecondaryButton = makeDarkSecondaryButton(Button)
export const OutlineButton = makeOutlineButton(Button)
