// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var ApolloHooks = require("reason-apollo-hooks/src/ApolloHooks.bs.js");
var Utils__Promise = require("../../../utils/Utils__Promise.bs.js");

function useMutation(client, variables, refetchQueries, awaitRefetchQueries, update, optimisticResponse, definition) {
  var match = ApolloHooks.useMutation(client, variables, refetchQueries, awaitRefetchQueries, update, optimisticResponse, undefined, undefined, definition);
  var mutate = match[0];
  var mutate$p = function (variables, client, refetchQueries, awaitRefetchQueries, optimisticResponse, param) {
    return Utils__Promise.fromJsPromise(Curry._6(mutate, variables, client, refetchQueries, awaitRefetchQueries, optimisticResponse, undefined));
  };
  return [
          mutate$p,
          match[1],
          match[2]
        ];
}

exports.useMutation = useMutation;
/* ApolloHooks Not a pure module */
