// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var TanDate = require("./common/types/TanDate.bs.js");
var Js_array = require("rescript/lib/js/js_array.js");
var CurrentUser = require("./common/CurrentUser/CurrentUser.bs.js");
var ReasonPromise = require("./ReasonPromise.bs.js");
var TANApolloHooks = require("./utils/apollo/TANApolloHooks.bs.js");

function toString(x) {
  switch (x) {
    case "Logout" :
        return "logout";
    case "Login" :
        return "login";
    case "DuplicateAddressWarning" :
        return "duplicate_address_warning";
    case "SubscriptionsReconnected" :
        return "subscriptions_reconnected";
    case "ClientHomePageAreaSearch" :
        return "client_home_page_area_search";
    case "ClientHomePagePropertySearch" :
        return "client_home_property_search";
    case "EmailActivityViewProfile" :
        return "email_activity_view_profile";
    
  }
}

var EventKey = {
  toString: toString
};

var ppx_printed_query = "mutation RecordEvent($input: RecordEventInput!)  {\nrecordEvent(input: $input)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value["recordEvent"];
  return {
          recordEvent: {
            success: value$1["success"]
          }
        };
}

function json_of_ActorType(value) {
  if (value === "USER") {
    return "USER";
  } else if (value === "CLIENT") {
    return "CLIENT";
  } else {
    return "QUALIFIED_AGENT";
  }
}

function json_of_RecordEventInput(value) {
  var v = value.data;
  return Js_dict.fromArray(Js_array.filter((function (param) {
                    return !Js_json.test(param[1], "Null");
                  }), [
                  [
                    "key",
                    value.key
                  ],
                  [
                    "actorType",
                    json_of_ActorType(value.actorType)
                  ],
                  [
                    "actorId",
                    value.actorId
                  ],
                  [
                    "occurredAt",
                    value.occurredAt
                  ],
                  [
                    "data",
                    v !== undefined ? v : null
                  ]
                ]));
}

function make(input, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray(Js_array.filter((function (param) {
                      return !Js_json.test(param[1], "Null");
                    }), [[
                      "input",
                      json_of_RecordEventInput(input)
                    ]])),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray(Js_array.filter((function (param) {
                      return !Js_json.test(param[1], "Null");
                    }), [[
                      "input",
                      json_of_RecordEventInput(input)
                    ]])),
          parse: parse
        };
}

function makeVariables(input, param) {
  return Js_dict.fromArray(Js_array.filter((function (param) {
                    return !Js_json.test(param[1], "Null");
                  }), [[
                    "input",
                    json_of_RecordEventInput(input)
                  ]]));
}

function definition_2(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray(Js_array.filter((function (param) {
                        return !Js_json.test(param[1], "Null");
                      }), [[
                        "input",
                        json_of_RecordEventInput(input)
                      ]])));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var GQL = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  json_of_ActorType: json_of_ActorType,
  json_of_RecordEventInput: json_of_RecordEventInput,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function make$1(key, ocurredAt, actorType, actorId, data, param) {
  return {
          key: key,
          occurredAt: ocurredAt.toISOString(),
          actorType: actorType,
          actorId: actorId,
          data: data
        };
}

var Input = {
  make: make$1
};

function useMutation(param) {
  return TANApolloHooks.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, definition);
}

var RecordEventMutation = {
  GQL: GQL,
  Input: Input,
  useMutation: useMutation
};

function useRecordEvent(param) {
  var match = useMutation();
  var mutate = match[0];
  return function (actor, data, key, param) {
    return ReasonPromise.map(Curry._6(mutate, makeVariables(make$1(toString(key), TanDate.now(), actor[0], actor[1], data, undefined), undefined), undefined, undefined, undefined, undefined, undefined), (function (param) {
                  
                }));
  };
}

function useRecordEventByCurrentUser(param) {
  var currentUser = CurrentUser.useCurrentSession();
  var recordEvent = useRecordEvent();
  return function (data, key, param) {
    var actor;
    if (typeof currentUser !== "object") {
      actor = undefined;
    } else if (currentUser.TAG === "Agent") {
      var match = currentUser._0;
      actor = match.isImpersonated ? undefined : [
          "USER",
          match.user.id
        ];
    } else {
      var match$1 = currentUser._0;
      actor = match$1.isImpersonated ? undefined : [
          "CLIENT",
          match$1.id
        ];
    }
    if (actor !== undefined) {
      return Curry._4(recordEvent, actor, data, key, undefined);
    } else {
      return ReasonPromise.resolved();
    }
  };
}

exports.EventKey = EventKey;
exports.RecordEventMutation = RecordEventMutation;
exports.useRecordEvent = useRecordEvent;
exports.useRecordEventByCurrentUser = useRecordEventByCurrentUser;
/* CurrentUser Not a pure module */
