import { LOGIN, LOGOUT } from '../constants/actionTypes'

export const logout = () => ({
  type: LOGOUT,
})

export const login = (loginData, redirectPath) => ({
  type: LOGIN,
  payload: {
    ...loginData,
    redirectPath,
  },
})
