/* eslint-disable new-cap */
import React from 'react'
import styled from 'styled-components'
import { useIntercom } from 'react-use-intercom'

import { Container } from '../MarkupHelpers'
import { Grid, Cell } from '../CssGrid'

const makeLinksList = showIntercom => [
  {
    title: 'About',
    href: 'https://www.topagentnetwork.com/about',
  },
  {
    title: 'Contact',
    href: 'https://www.topagentnetwork.com/contact',
    onClick: e => {
      e.preventDefault()
      showIntercom()
    },
  },
  {
    title: 'Privacy',
    href: 'https://www.topagentnetwork.com/privacy-policy',
  },
  {
    title: 'Terms of Service',
    href: 'https://www.topagentnetwork.com/terms-of-service',
  },
  {
    title: 'Usage Rules',
    href: 'https://www.topagentnetwork.com/usage-rules',
  },
]

const StyledFooter = styled.footer`
  font-size: 13px;
  padding: 25px 0;
  background: ${props => props.theme.colors.white};
  text-align: center;
`

const LinksList = styled(Grid)`
  font-size: 13px;
  margin: 0;
  padding: 0;
  a {
    color: ${props => props.theme.colors.gray};
    transition: 150ms;
    &:hover {
      color: ${props => props.theme.colors.gray};
      text-decoration: underline;
    }
  }
`

const LinkItem = styled(Cell)`
  white-space: nowrap;
`

const Footer = () => {
  const currentYear = new Date().getFullYear()
  const { show } = useIntercom()
  const linksList = makeLinksList(show)

  return (
    <StyledFooter>
      <Container>
        <Grid
          columns={1}
          columnsMd={2}
          rows={2}
          rowsMd={1}
          columnsWidth="auto"
          columnsWidthMd="auto"
          gap="20px 0"
          gapMd="0 10px"
          justifyContentMd="space-between"
        >
          <Cell>&copy; {currentYear} Top Agent Network</Cell>
          <Cell>
            <LinksList
              gap="15px 0"
              gapSm="0 30px"
              columns={1}
              columnsSm={linksList.length}
              rows={linksList.length}
              rowsSm={1}
              columnsWidthSm="auto"
            >
              {linksList.map((link, index) => (
                <LinkItem key={index}>
                  <a {...(link.onClick && { onClick: link.onClick })} href={link.href}>
                    {link.title}
                  </a>
                </LinkItem>
              ))}
            </LinksList>
          </Cell>
        </Grid>
      </Container>
    </StyledFooter>
  )
}

export default Footer
